import { createApp } from 'vue'
import App from './App.vue'
import pinia from '@/store'
import router from './router'
import ElementPlus from 'element-plus'
import * as ElIcons from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import VueI18n from '@/i18n/index'
import vue3videoPlay from 'vue3-video-play'
import 'vue3-video-play/dist/style.css'
import axios from '@/utils/request'
import MetaInfo from 'vue-meta-info'

const app = createApp(App)

app.config.globalProperties.$axios = axios;

app.use(ElementPlus)
app.use(router)
app.use(pinia)
app.use(VueI18n)
app.use(vue3videoPlay)
app.use(MetaInfo)
app.mount('#app')

