<template>
  <div class="com_header_area">
    <div class="header_con">
      <el-image class="logo" :src="logo" @click="toIndexPage"/>
      <div class="header_right">
        <div class="header_search"></div>
        <div class="header_nav" @mouseleave="changeOutShow">
          <div class="header_nav_title">
            <router-link class="header_nav_item" v-for="item in navListData" :key="item.id" 
              @mouseover.stop="changeHoverShow(item.id)" :to="item.url"
              :style="{backgroundColor: item.id === hoverId ? 'rgb(44,62,80)' : '',color:item.id === hoverId ? '#fff' : ''}"
              v-show="!(item.isNotShow?.includes(locale))"
            >{{ $t(`${item.name}`) }}</router-link>
          </div>
          <div class="header_nav_child" v-if="navChildrenList.length">
            <div class="header_child_con" :class="hoverId ? 'show' : ''">
              <div class="header_child_item" v-for="item in navChildrenList" :key="item.id" v-show="!(item.isNotShow?.includes(locale))">
                <a v-if="item.isBlank" :href="item.url" target="_blank">{{ $t(`${item.name}`) }}</a>
                <router-link v-else :to="item.url">
                  <span v-if="item.name === 'nav.EVIBB' && locale != 'ja'">{{ $t(`nav.EVIBB01`) }} {{ $t(`nav.EVIBB02`) }}</span>
                  <span v-else>{{ $t(`${item.name}`) }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import router from '@/router';
import { defineComponent,ref } from 'vue';
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'headerView',
  setup(){
    const { locale } = useI18n()

    interface navList {
      id:number,
      name:string,
      url:string
      isBlank?:boolean
      isNotShow?:string[]
      children?:navList[]
    }
    const navListData = ref<navList[]>([
      {
        id:1,
        name:'home',
        url:'/index'
      },
      {
        id:2,
        name:'nav.about',
        url:'/about',
        children:[
          {
            id:201,
            name:'nav.aboutCompany',
            url:'/about',
            isNotShow:['ja']
          },
          {
            id:202,
            name:'nav.developmentHistory',
            url:'/develop',
            isNotShow:['en','ja']
          },
          {
            id:203,
            name:'nav.memorabilia',
            url:'/memorabilia',
            isNotShow:['en','ja']
          },
          {
            id:204,
            name:'nav.operation',
            url:'/operation',
            isNotShow:['ja']
          },
          {
            id:205,
            name:'nav.certificationSystem',
            url:'/quality'
          },
          {
            id:206,
            name:'nav.contact',
            url:'/contact'
          },
          {
            id:207,
            name:'nav.careerDevelopment',
            url:'/careerDevelopment',
            isNotShow:['en']
          },
          {
            id:208,
            name:'nav.joinUs',
            url:'/joinUs',
            isNotShow:['en','ja']
          },
        ]
      },
      {
        id:3,
        name:'nav.proServices',
        url:'/proServe',
        children:[
          {
            id:301,
            name:'nav.powder',
            url:'/powder',
          },
          {
            id:302,
            name:'nav.edgeClosed',
            url:'/edge',
          },
          {
            id:303,
            name:'nav.EVIBB',
            url:'/flexView',
          },
          {
            id:304,
            name:'nav.complete',
            url:'/solution',
          },
        ]
      },
      {
        id:4,
        name:'nav.industrySolutions',
        url:'/solution/index',
        children:[
          {
            id:401,
            name:'nav.solarPower',
            url:'/pv',
          },
          {
            id:402,
            name:'nav.windPower',
            url:'/wind',
          },
          {
            id:403,
            name:'nav.transportation',
            url:'/traffic',
          },
          {
            id:404,
            name:'nav.powerGrid',
            url:'/power',
          },
          {
            id:405,
            name:'nav.electricalVehicle',
            url:'/ecar',
          },
          {
            id:406,
            name:'nav.energyStorage',
            url:'/energyStorage',
            isNotShow:['ja']
          },
          {
            id:407,
            name:'nav.other',
            url:'/other',
          },
        ]
      },
      {
        id:5,
        name:'nav.engineering',
        url:'/technology',
        children:[
          {
            id:501,
            name:'nav.laminatedBusbarDesign',
            url:'/design',
          },
          {
            id:502,
            name:'nav.analysis',
            url:'/test',
          },
        ]
      },
      {
        id:6,
        name:'nav.design',
        url:'/c1',
        isNotShow:['ja','en'],
        children:[
          {
            id:601,
            name:'nav.laminated',
            url:'/c1',
            isNotShow:['ja','en'],
          },
          {
            id:602,
            name:'nav.parallel',
            url:'/c2',
            isNotShow:['ja','en'],
          },
        ]
      },
      {
        id:7,
        name:'nav.quality',
        url:'/quality',
        children:[
          {
            id:701,
            name:'nav.qualityAssurance',
            url:'/quality',
            isNotShow:['en','ja'],
          },
          {
            id:702,
            name:'nav.technology',
            url:'/technique',
          },
          {
            id:703,
            name:'nav.capability',
            url:'/technique',
          },
        ]
      },
      {
        id:8,
        name:'nav.investorRelations',
        url:'/relation/index',
        isBlank:false,
        isNotShow:['en','ja'],
        children:[
          {
            id:801,
            name:'nav.companyAnnouncement',
            url:'http://www.sse.com.cn/assortment/stock/list/info/company/index.shtml?COMPANY_CODE=603312',
            isBlank:true,
            isNotShow:['en','ja'],
          },
          // {
          //   id:802,
          //   name:'nav.investorCommunication',
          //   url:'https://sns.sseinfo.com/company.do?stockcode=603312',
          //   isBlank:true,
          //   isNotShow:['en','ja'],
          // },
          {
            id:803,
            name:'nav.investorServices',
            url:'/relation/services',
            isBlank:false,
            isNotShow:['en','ja'],
          },
        ]
      }
    ])
    const navChildrenList = ref<navList[]>([])
    const hoverId = ref<number | null>(null)
    const changeHoverShow = (val:number) => {
      hoverId.value = val
      let arr = navListData.value.filter((item:navList) => item.id === val)
      navChildrenList.value = arr.length != null && arr.length > 0 ? arr[0]?.children ? arr[0]?.children : [] : []
    }
    const changeOutShow = () => {
      hoverId.value = null
      navChildrenList.value = []
    }

    const logo = ref('/image/logo_01.png')
    const toIndexPage = () => {
      router.push('/index')
    }

    return {
      locale,
      navListData,
      navChildrenList,
      hoverId,
      changeHoverShow,
      changeOutShow,
      logo,
      toIndexPage
    }
  }
})
</script>

<style scoped lang="less">
.com_header_area{
  width: 100%;
  height: 82px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow:0px 5px 10px #333 ;
  position: absolute;
  left: 0;
  z-index: 99;
  .header_con{
    width: 1280px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: rgb(51,51,51);
    .logo{
      width: 159px;
      height: 52px;
      cursor: pointer;
    }
    .header_right{
      height: 52px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .header_nav{
        width: 100%;
        position: relative;
        .header_nav_title{
          width: 100%;
          display: flex;
          align-items: center;
          .header_nav_item{
            display: inline-block;
            padding: 0 15px;
            cursor: pointer;
            border-left: 1px solid rgb(44,62,80);
            &:first-child{
              border-left: 0px;
            }
            &:hover{
              color: rgb(255,255,255);
              background-color: rgb(44,62,80);
            }
          }
        }
        .header_nav_child{
          .header_child_con{
            display: none;
            width: 100%;
            left: 0px;
            position: absolute;
            padding-bottom: 10px;
            background-color: rgb(44,62,80);
            z-index: 1099;
            flex-wrap: wrap;
            .header_child_item{
              cursor: pointer;
              margin: 10px 30px;
              a{
                font-size: 12px;
                color: rgb(255,255,255);
                &:hover{
                  color: rgb(184,255,255);
                }
              }
            }
          }
          .show{
            display: flex;
          }
        }
      }
    }
  }
}
</style>
  