export default {
    companyName:'Suzhou West Deane Machinery Inc.',
    home:'Home',
    nav:{
        about:'About Us',
        aboutCompany:'Company Info',
        developmentHistory:'Development History',
        memorabilia:'Memorabilia',
        operation:'Operation and ability',
        certificationSystem:'Quality Excellence',
        contact:'Contact Us',
        careerDevelopment:'Career development',
        joinUs:'Join Us',
        proServices:'Products & Services',
        powder:'Powder Painted Laminated Bus Bar',
        edgeClosed:'Edge-Closed Laminated Bus Bar',
        EVIBB01:'EV-CCS',
        EVIBB02:'Bus Bars',
        complete:'Complete Power Solutions',
        industrySolutions:'Industry Solutions',
        solarPower:'Solar Power',
        windPower:'Wind Power',
        transportation:'Rail and Transportation',
        powerGrid:'Power Grid',
        electricalVehicle:'Electrical Vehicle',
        energyStorage:'Energy Storage',
        other:'Other Industrial Applications',
        engineering:'Engineering & Innovation',
        laminatedBusbarDesign:'Laminated Busbar Design',
        analysis:'Analysis and Test',
        design:'Design Tool',
        laminated:'Calculator for Laminated Bus-bar',
        parallel:'Calculator for Parallel Bus-bar',
        quality:'Quality Excellence',
        qualityAssurance:'WDI Quality Excellence',
        technology:'Proprietary Process Technology',
        capability:'WDI Manufacturing Capabilities',
        investorRelations:'Investor Relations',
        companyAnnouncement:'Company Announcement',
        investorCommunication:'Investor Communication',
        investorServices:'Investor Services'
    },
    index:{
        indexBannerBottom:'Suzhou West Deane New Power Electric Co.,Ltd (WDI) is a Canadian company which has manufacturing facilities in Suzhou and Chengdu, China. WDI is the technology leader in Laminated Bus Bar（LBB）and CCS solutions for power conversion and distribution applications. As a long-term partner, WDI devotes to working closely with customers in co-engineering and design, providing laminated bus bar and CCS solutions to customers worldwide.',
        index3ProDesc:'Our Laminated Bus Bar products are manufactured by state of art process technology…',
        index3IndustryDesc:'Over the years, WDI provided reliable solutions for tough environmental conditions and higher industry requirements…',
        index3Engineering:'Our experienced design and application engineers provide optimal design and help our customers speed up product time to market',
        loadMore:'MORE INFO',
    },
    about:{
        aboutDesc01:`Suzhou West Deane New Power Electric Co.,Ltd (WDI) is an Engineering and Manufacturing company who provides Laminated Bus Bar (LBB) and CCS products, and complete engineering solutions to customers worldwide.`,
        aboutDesc02:`Our customers range from renewable power industry (solar power, wind power), transportation, utility networks, and other power conversion industry, EV battary, energy storage etc..`,
        aboutDesc03:`Through our quality products and value-added engineering services, WDI has become the key link of global supply chain to our customers and part of their successes.`,
    },
    operation:{
        title:'Our strengths and capabilities',
        desc01:'- Zero-defect high quality Laminated Bus Bar products.',
        desc02:'- Co-current design, value engineering, R&D.',
        desc03:'- Quick-prototyping capabilities to speed up new product time to market.',
        desc04:'- Domestic and overseas sales & program management team.',
        title01:'Quality Excellence',
        desc05:`We are an IRIS (International Railway Industry Standard) and TS16949  certified company. We are committed to continuously improve our products and services, to meet or to exceed customer's expectations.`,
        title02:'Our ultimate goal is 100% customer satisfaction.',
    },
    careerDevelopment:{
        carDesc:'西典公司从最开始就把持续改进，不断创新的理念融入到我们的设计和生产。公司的发展和成功离不开一个高水准，团结向上，勇于创新的团队。',
        carDesc01:'为我们的员工提供可靠的薪资福利，理想的工作环境，和学习发展的机会是公司的长期重要目标。',
        carDesc02:'我们欢迎各方面的人才加入西典，同我们的员工一道，随着公司的发展而成长。',
        carDesc03:'请标明您想应聘的部门和职位, 并通过以下方式联系我们：',
        carDesc03TEL:'TEL: 86-512-66165970',
        carDesc03Email:`Email: infor{'@'}wdint.com`
    },
    contact:{
        companyName:'Suzhou West Deane Machinery Inc.',
        address:'Address: No. 353 Jin Feng Road, New district, Suzhou, China 215151',
        zipCode:'邮编：215151',
        phone:'Tel: 86-512-66165970',
        fax:'Fax: 86-512-66572805',
        email:`Email: infor{'@'}wdint.com`,
        website:'网站：www.wdint.com',
        workTime:'Office Hours: Mon - Fri, 8:30 AM-5:00 PM (Beijing Time)',
    },
    proServe:{
        title01:'What is Laminated Bus Bar',
        title01Desc:`WDI's Laminated Bus Bar (LBB), is a multi-layer custom copper bus bar. It acts as the "Express Way" for power distribution system. WDI's laminated bus bars are manufactured by state-of-art technology. They provide modern, compact, easy installation and high electrical performances, comparing to traditional complex, cumbersome, wiring system.`,
        title02:'WDI Laminated Bus Bar Benefits',
        title02Desc01:'- Total lower system costs',
        title02Desc02:'- Error- free installation',
        title02Desc03:'- High reliability and security',
        title02Desc04:'- Customized modular form for ease of installation and field service',
        title02Desc05:'- Space savings through compact design',
        title02Desc06:'- High distributed capacitance',
        title02Desc07:'- Low Inductance',
        title02Desc08:'- High current carrying capability with minimal voltage drop',
        title02Desc09:'- Better heat dissipation',
        title02Desc10:'- Low characteristic impedance',
        title03:'WDI Bus Bar Product Portfolios',
    },
    powder:{
        powderCon01:`WDI's Powder-painted Laminated Bus Bars, are manufactured using our proprietary powder-painting process and laminating technologies.`,
        powderCon02:`High performance insulating powder provides a continuous, tough, moisture and chemical resistant dielectric coating for our LBB's.`,
        powderCon03:`Our dielectric coating thickness ranges between 0.2 mm ~ 3.00 mm, based on customers' various applications and electrical requirements.`,
        powderCon04:`WDI's powder-painted LBB's offers a smooth, glossy appearance and superb electrical performance. It provides superior performance to meet high voltage / current, or very complex structural requirements.`,
    },
    edgeClosed:{
        edgeClosedCon01:`WDI's edge-closed laminated bus bars (LBB) are manufactured by our proprietary laminating process technologies.`,
        edgeClosedCon02:`Our Edged Closed LBBs use UL recognized, high voltage and heat resistant insulating materials, which provide superior performance required to meet various industry applications. `,
        edgeClosedCon03:`Our experienced design and application engineers work closely with customers and provide complete design solutions. The maximum size of our edge-closed LBBs could be 2200 mm x 800 mm.`,
    },
    flex:{
        flexTitle_01:'EV-CCS',
        flexTitle_02:'Battery Management Solution',
        flexDesc01:'CCS, (Integrated Bus Bar for Battery), a single piece bus bar solution, integrating PCB/FPC(Signal control) and LBB(Power distribution).',
        flexDesc03:`Learn more about WDI's leading bus bar solutions for EV >>>`,
        flexDesc04:'For more comprehensive solutions on EV-CCS integrated bus bar (CCS) electrochemical energy storage, please visit us >>>',
    },
    complete:{
        completeDesc01:`Capitalize on our expertise and broad knowledge on power electronics, mechanics and materials, our engineers work closely with customers and provide unique, reliable solutions based on various application requirements – such as electrical, structural, and environmental requirements.`,
        completeDesc02:`Examples of our LBB solutions, the advantages and recommended applications  are listed below:`,
        completeTable00:`Functional Requirement`,
        completeTable01:`Edge-Closed LBB (PET film insulation)`,
        completeTable02:`Edge-Closed LBB (Nomex film insulation)`,
        completeTable03:`Powder-Painted LBB(Epoxy- powder insulation)`,
        completeTable10:`Nominal Input Current (A)`,
        completeTable11:'10~1000',
        completeTable12:'10~1000',
        completeTable13:'10~2000',
        completeTable20:`Rated Operational Voltage (V)`,
        completeTable21:'380~4000',
        completeTable22:'380~4000',
        completeTable23:'380~4000',
        completeTable30:`Max. Operating Temperature(℃)`,
        completeTable31:'130',
        completeTable32:'125',
        completeTable33:'130',
        completeTable40:`Creepage Distance`,
        completeTable41:`Relatively smaller（Can be improved by adding insulating slots or bars`,
        completeTable42:`Relatively smaller（Can be improved by adding insulating slots or bars`,
        completeTable43:'Bigger',
        completeTable50:'Humidity Resistance',
        completeTable51:'Good',
        completeTable52:'Poor',
        completeTable53:'Good',
        completeTable60:`Structural Complexity`,
        completeTable61:`Suitable for simple, flat structure with certain degree of bending`,
        completeTable62:`Suitable for simple, flat structure, no bending `,
        completeTable63:`Can be designed for very complex structure`,
        completeTable70:`Nominal Conductor Thickness (mm)`,
        completeTable71:'Suggested ≤ 4mm',
        completeTable72:'Suggested ≤ 4mm',
        completeTable73:'Suggested 2~10',
    },
    industrySolutions:{
        title:'Laminated Bus Bar Applications',
        inSolutionDesc01:`- Renewable Energy (Solar Power, Wind Power)`,
        inSolutionDesc02:`- Rail/Transportation `,
        inSolutionDesc03:`- Telecommunications`,
        inSolutionDesc04:`- Power conversion and other industrial applications `,
    },
    pv:{
        pvDesc01:`For many years, WDI has delivered high performance Laminated Bus Bar solutions to our customers – solar inverter manufacturers. From the sunny coast of Spain to the Northwest Gobi desert of China, there are hundreds of thousands WDI laminated bus bars performing safely and stably.`,
        pvDesc02:`The solar industry faces big challenges - increasing power conversion efficiency, reducing the unit cost of power generation, and system reliability. `,
        pvDesc03:`WDI works closely with our customers to solve these challenges. Our engineers provide customers optimal design to reduce costs from earlier design stage. `,
        pvProDesc01:`An epoxy powder painted laminated bus bar solution for solar inverter application. The maximum working temperature can be 125 ℃. All bushings are insulated by epoxy powder, therefore the creepage distance can reach 30 mm.`,
        pvProDesc02:`An edge-sealed laminated Bus Bar solution. In this laminated bus bar design by our engineers, the thermal performance was enhanced, while the overall weight and product cost was reduced.`,
    },
    wind:{
        windDesc01:`The wind power industry faces challenges of high power generation cost, tough working conditions. These challenges require laminated bus bars to have very high reliability to meet various wind environments.`,
        windDesc02:`Benefit from our vast knowledge and experience on laminated bus bar designs for different wind environments, WDI has solved the technical challenges and delivered various solutions to meet the specific power generation applications ranging from off-shore, high altitude land, to low wind speed wind conditions.`,
        windProDesc01:`A example of WDI's multiple solutions for an high power off-shore wind converter.`,
        windProDesc02:`The Capacitor bus bar used Edge-sealed design to ensure the Creepage Distance and Minimum Clearance, while greatly reduced bus bar inductance.<br />The IGBT bus bar used poxy-powder painted design, which enhanced the electrical performance, especially increased`,
    },
    traffic:{
        trafficDesc01:`The working conditions of Rail and Transportation are hush. The environmental conditions such as Shock & Vibration, Ambient Temperature, Humidity, Altitude etc. demand Laminated bus bars to be highly durable. The vehicles must always operate safely and reliably. `,
        trafficDesc02:`Our engineers have extensive knowledge and experience on LBB design for Rail and transportation applications. The quality of our design and manufacturing is ensured and compliant to IRIS (International Railway Industry Standard) quality system. `,
        trafficProDesc01:`An example of our LBB solution for traction converter. The rated working temperature is 2800 V. This design effectively solved the difficulty of limited module space and weight requirements, while meeting the electrical specifications.`,
        trafficProDesc02:`Another example of our LBB design for traction converter. Our Engineers used the combination of WDI's edge-closed LBB and powder-painted LBB solutions. It is a compact design which effectively saved space while enhanced the electrical performance.`,
        trafficProDesc03:`A typical use of WDI powder painted bus bar in traction converters. The bus bar's coating thickness is 2 mm. It can withstand working voltage 9,600 V.`,
    },
    power:{
        powerTitle:`Power Distribution & Power Conversion`,
        powerDesc01:`Smart Grid is about the big picture of improved energy delivery through integration of renewable energy sources, smart transmission and distribution.`,
        powerDesc02:`The application requirements for laminated bus bars are typical in high voltage, large current conditions. Laminated bus bars must be safe and reliable. `,
        powerDesc03:`WDI pioneered in this industry. We are one out of the very few companies who could provide LBB products to meet the application requirements, and in the leading position for bus bar design and system solutions.`,
        powerProDesc01:`This is an example of WDI's powder-painted bus bar solution for HVDC, and other power transmission applications. Our epoxy powder bus bar provided superior dielectric performance, withstanding higher voltage and working temperature. In this design, the epoxy powder painted bus bar could better meet the electrical clearance requirements. The compact design also saved space and costs.`,
        powerProDesc02:`This is an example of edge-sealed bus bar solution for SVG application. By using WDI's patented lamination technology, the creepage distance was increased and product's electrical performance was enhanced. The structural space was also saved.`,
    },
    ecar:{
        ecarDesc01:`The successful commercialization of HEVs in recent years indicates that the industry is entering a new era. Vehicle manufacturers have invested more resources on the research and development of the EV. This will in turn requires partner suppliers to have high level of design and R& D capabilities.`,
        ecarDesc02:`WDI has entered this industry at very early stage. We have partnered with our customers and delivered bus bar and CCS solutions for Hybrid & Electric Mobility.`,
        ecarProDesc0101:`EV-CCS Battery management solution`,
        ecarProDesc0102:`CCS, (Integrated Bus Bar for Battery), a single piece bus bar solution, integrating PCB/FPC (Signal control) and LBB (Power distribution).`,
        ecarProDesc0103:`- Integrate temperature signal and voltage signal acquisition`,
        ecarProDesc0104:`- Integrate connectors`,
        ecarProDesc0105:`- Integrate fuse protection and other SMT electronic components`,
        ecarProDesc0106:`- Eliminate wiring errors`,
        ecarProDesc0107:`- Reduce welding and complex assembly process`,
        ecarProDesc0108:`- Space is more compact`,
        ecarProDesc0109:`- Superior and reliable performance`,
        ecarProDesc0110:`- Highly integrated, suitable for automated mass production`,
        ecarProDesc0201:`EV drive control system solution`,
        ecarProDesc0202:`- Minimal inductance`,
        ecarProDesc0203:`- Suitable for complex power control and connection`,
        ecarProDesc0204:`- Space is more compact and smaller`,
        ecarProDesc0205:`- Multiple insulation solutions (epoxy powder, PET, PI, etc.)`,
        ecarProDesc0206:`- To meet higher ambient temperature requirements.`,
        ecarProDesc0207:`- Resistance to harsh environments (oil, salt spray, vibration etc.)`,
    },
    es:{
        esDesc01:'WDI pioneered introducing Energy Storage industries. Our laminated products have been successfully used on many key projects in mass production.',
        esDesc02:'',
        esPro01Title:'LBB for PCS solution',
        esProDesc01Desc01:'- Compact, easy installation',
        esProDesc01Desc02:'- Low inductance, superior performance',
        esProDesc01Desc03:'- Higher current carrying capacity',
        esPro02Title:'CCS for energy storage solution',
        esProDesc02Desc01:'- Strong anti-aging capabilities, longer product life',
        esProDesc02Desc02:'- FFC solution has good signal acquisition as an alternative of FPC.',
        esProDesc02Desc03:'- Higher current carrying capacity',
        esProDesc02Desc04:'- Max size CCS: 2000 mm x 500 mm',
    },
    other:{
        otherDesc01:'Other Industries such as mining, steel-making, chemical, HVAC have also raced to build increasingly intelligent systems to increase the utilization of energy and to improve efficiency. ',
        otherDesc02:`WDI's Laminated bus bars have the benefits of low inductance, superior dielectric performance, high reliability in heavy duty and tough environmental conditions. Our LBB products are increasingly used in many other industrial applications.`,
    },
    technology:{
        technologyDesc01:`Our experienced engineering team includes Professional Engineers of Canada, and senior engineers with advanced degree M.Sc. and Ph. D. They have broad knowledge in power electronics, mechanics and materials, and engineering design experiences.`,
        technologyDesc02:`Leveraging cross-industry innovation and proven engineering expertise, we're pushing the boundaries of efficiency and productivity - through our engineering concepts of increasing product performance and decreasing size, weight, complexity, cost.`,
        technologyTitle03:`Co-current Design`,
        technologyDesc03:`Our engineers take a systems-wide view of designs and provide complete engineering solutions to help customers speed up product time to market. We use CAD and Solid works as the design tools to streamline the design process.`,
        technologyTitle02:`Project Management`,
        technologyDesc08:`Our program managers have the unrivalled project management skills and experiences in managing engineering projects from concept design, new product development to batch production. `,
        technologyTitle01:`Value engineering`,
        technologyDesc04:`- New product development from concept to batch production.`,
        technologyDesc05:`- Re-design products for better performance and quality at reduced costs.`,
        technologyDesc06:`- Alternative manufacturing method study, process improvement, material replacement.`,
        technologyDesc07:`- Proprietary Quick-Prototyping technology – shorten the lead time for samples`,
    },
    design:{
        designTitle:`How to Design a Laminated Bus Bar?`,
        designTitle01:`The main considerations of laminated bus bar design`,
        designDesc01:`The laminated bus bar carries the loads and the sources of electric power in an power conversion module, providing the lowest inductance interconnection for IGBT and capacitors. When our Engineers work on a Laminated Bus Bar design, we have many considerations, such as the circuit diagram, the physical arrangement of IGBTs, Capacitors etc., heat dissipation requirements, electrical requirements and structural / installation requirements.`,
        designTitle02:`The effect of induction to power- conversion system`,
        designDesc0201:`A good design can make the Laminated Bus Bar to achieve the lowest inductance.`,
        designTitle03:`Reduce the temperature rise of the system`,
        designDesc03:`Exceeding the allowed environmental temperature range can cause overstressing of an inverter or convertor system, sometimes could cause the total system failure. Laminated Bus Bar is one of the critical components in a power conversion system. How to reduce temperature rise is one of our engineer's main considerations in a Laminated Bus Bar design.`,
        designTitle04:`Meet the electrical requirements (Dielectric Strength, Partial Discharge, Creepage Distance requirements etc.)`,
        designDesc04:`Our design engineers can choose from a variety of material, insulation and plating options, including various methods to increase creepage distance, together with WDI's advanced process technology, to meet the electrical requirements in different industry applications.`,
        designTitle05:`Manufacturability, Costs`,
        designDesc05:`When designing a Laminated Bus Bar, the designer also needs to consider the manufacturing and process related factors of laminated bus bar, in addition to the functional requirements. Otherwise, a laminated bus bar may be designed to be very complex for manufacturing and higher in process cost. <br />This is one of the areas that we can utilize our knowledge and expertise to provide value-added design services for customers.`,
        designTitle06:`Installation and Structural requirements`,
        designDesc06:`A good laminated bus bar design also needs to meet the structural and mechanical requirements of the application.`,
    },
    test:{
        testTitle01:`Temperature Rise Analysis and Test`, 
        testDesc01:`At the design stage, our Engineers use simulation and testing methods to perform temperature rise analysis. The results are used by our engineers to verify and validate the laminated bus bar design parameters. The temperature rise test on laminated bus bar can be performed at WDI's own lab.`,
        testDesc02Title:`Notes: `,
        testDesc02:`The above stated test is only on Laminated Bus Bar. We recommend our customers to perform tests after the laminated bus bar is installed in their own system. As Laminated bus bars are interacted with other components such as IGBT and Capacitors. The distance between bus bar and other components, the air flow conditions, the temperature rise of other components etc. will also have impact on the temperature rise of laminated bus bar.`,
        testTitle02:`Inductance Test`,
        testDesc03:`After a prototype is built, our Engineers perform the inductance test, to verify and validate the initial design. This test can be conducted by using  high precision test apparatus at WDI's lab.`,
        testDesc04:`WDI's lab is also equipped with other advanced testing instruments and apparatus to provide various electrical and mechanical testing capabilities.`,
    },
    c1:{
        c1Title:`Calculator for Laminated Bus-bar`,
        c1FormLabel01:`Dielectric coefficient of insulator (k)`,
        c1FormLabel02:`Thickness of insulator layer (a: mm)`,
        c1FormLabel03:`Number of layers of conductor`,
        c1FormLabel04:`Thickness of copper layer (t: mm)`,
        c1FormLabel05:`Length of copper layer (l: mm)`,
        c1FormLabel06:`Width of copper layer (b: mm)`,
        c1FormLabel07:`Temperature rise allowed (℃)`,
        c1FormLabel08:`Hi-pot test voltage (VAC)`,
        c1FormSubmit:`Calculate`,
        c1FormCancel:`Reset`,
        c1TableTitle:`Result`,
        c1Table01:`Max AC　current allowed (bus-bar placed vertically)`,
        c1Table02:`Max AC　current allowed (bus-bar placed horizontally)`,
        c1Table03:`Max DC　current allowed (bus-bar placed vertically)`,
        c1Table04:`Max DC　current allowed (bus-bar placed horizontally)`,
        c1Table05:`Inductance L (nH)`,
        c1Table06:`Capacity C (pF)`,
        c1Table07:`Leakage current (mA)`,
        c1TableDesc:`Note:The above are rough calculation only. The actual bus bar design parameters may need to vary according to detailed structural requirements, working conditions etc..  Please contact our Engineering at: infor{'@'}wdint.com`,
    },
    c2:{
        c2TableLabel01:`Centre-to-centre spacing of two conductors d(mm)`,
        c2TableLabel02:`Conductor width b (mm)`,
        c2TableLabel03:`Conduct thickness h (mm)`,
        c2TableLabel04:`Conductor length l (mm)`,
        c2TableResult:`Inductance L (nH)`,
        c2TableSubmit:`Calculate`,
        c2TableCancel:`Reset`,
        c2TableDescTitle:`Note:`,
        c2TableDesc01:`1：Centre-to-centre spacing shall be larger than conductor width.`,
        c2TableDesc02:`2：Conductor thickness shall be smaller than conductor width.`,
        c2TableDesc03:`The above are rough calculation only. The actual bus bar design parameters may need to vary according to detailed structural requirements, working conditions etc.. Please contact our Engineering at: infor{'@'}wdint.com`,
    },
    certificationSystem:{
        breadTitle:'Quality Excellence',
        title:'Quality Excellence',
        cerDesc:'WDI has advanced testing capabilities, such as High Pot Test, Partial Discharge Test, Contact Resistance Test and mechanical tests - dimensional, cleavage tests etc. to ensure high quality products to meet your exacting specifications and ours.',
        cerDesc01:'Paper-less, computerized document control system at every work station, makes the drawings control accurate and efficient, especially for low volume, high mix products.',
        cerDesc02:'Each product has its unique bar code, to realize total product traceability from raw materials, in-processes, to final assembly & testing.',
        cerDesc03:'WDI received numerous rewards from our customers for our track records of Quality Excellence and Innovative Design.',
        title01:'WDI is ISO 9001 , IRIS and TS16949 certified company',
        cerDesc04:`The certification of IRIS (International Railway Industry Standard) and TS16949 system is part of WDI's continuous-improvement effort toward the highest level of total quality, product life-cycle and project management.`,
    },
    technique:{
        techniqueTitle:'Proprietary Process Technology',
        techniqueDesc01:`Striving for innovation and excellence runs through the whole life cycle of WDI's laminated bus bar products – from product design, manufacturing, final assembly & testing, to product's long term reliability.`,
        techniqueDesc02:`WDI is in the leading position for its advanced "Z" or "L'' shaped bending / lamination process technology, epoxy powder painting technology for high voltage applications, and quick -prototyping technologies`,
        techniqueTitle01:`Prototype Development`,
        techniqueDesc03:`Our innovative Flexible Quick Prototyping technology ( WDI's patented technology), helps our customers shorten the sample development time, reduce the time for new products into market, and also reduce the investment cost on R&D projects. `,
        techniqueTitle02:`Lamination Technology`,
        techniqueDesc04:`WDI's advanced automatic lamination machines. All process parameters can be precisely controlled, which ensures product performance and consistent quality.`,
        techniqueDesc05:`Proprietary L and Z shape LBB process technology. These types of LBBs can be laminated / bent in one step instead of the traditional multi process, which reduces the damages to insulation material, and improves product's partial discharge performance.`,
        techniqueTitle03:`WDI Manufacturing Capabilities`,
        techniqueDesc06:`In-house manufacturing equipment and production lines for all key processes – such as CNC lathering, CNC punching / forming, brazing, laminating, powder painting, and testing, to ensure internal control of critical processes and total product quality.`,
    },
    login:{
        email:'EMail: ',
        password:'Password: ',
        code:'Verification Code: ',
        submit:'Login',
        cancel:'Reset'
    },
    register:{
        email:'EMail: ',
        password:'Password: ',
        submitPassword:'Enter Password: ',
        contacts:'Contact: ',
        company:'Company: ',
        address:'Address: ',
        mobile:'Mobile: ',
        phone:'Tel: ',
        fax:'Fax: ',
        code:'Verification Code: ',
        submit:'Register',
        cancel:'Reset',
    }
}