<template>
    <el-container>
        <el-header class="header_area" height="123px">
            <div class="header_lang_area">
                <div class="header_lang">
                    <span @click="changeLanguage('en')" style="border-right: 1px solid #fff;">English</span>
                    <span @click="changeLanguage('zhCn')" style="border-right: 1px solid #fff;">中文</span>
                    <span @click="changeLanguage('ja')">日本语</span>
                </div>
            </div>
            <header-view />
        </el-header>
        <el-main class="main_area">
            <router-view/>
            <footer-view />
        </el-main>
        <el-footer class="footer_area" height="80px">
            <div style="display: flex;align-items: center;">
                &copy;&nbsp;2018&nbsp;{{ $t('companyName') }}&nbsp;&nbsp;&nbsp;&nbsp;
                <a v-if="locale === 'zh_CN'" style="color: #cccccc;" href="https://beian.miit.gov.cn/" target="_blank">苏ICP备11026994号-1</a>
                <a href="https://www.beian.suzhou.gov.cn/license?id=1186174914034401342" target="_blank">
                    <img src="http://www.beian.suzhou.gov.cn/resources/images/marker/szicbok.gif" style="width: 31px;margin-left: 10px;" alt="">
                </a>
            </div>
            <div>
                <span style="padding: 0 10px;">Tel:&nbsp;86-512-6616 5970</span>
                <!-- <span>Fax:&nbsp;86-512-6657 2805</span> -->
                <span>Email:&nbsp;infor@wdint.com</span>
            </div>
        </el-footer>
    </el-container>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance } from 'vue';
import { useI18n } from 'vue-i18n'
import HeaderView from '@/components/header.vue'
import FooterView from '@/components/footer.vue'; 

export default defineComponent({
    name: 'layout',
    components: { HeaderView, FooterView},
    setup(){
        const { proxy } = getCurrentInstance() as any;
        const { locale } = useI18n()

        const changeLanguage = (val:string) => {
            if(val === 'zhCn') {
                proxy.$i18n.locale = 'zh_CN'
            } else if(val === 'en') {
                proxy.$i18n.locale = 'en'
            } else if(val === 'ja') {
                proxy.$i18n.locale = 'ja'
            }
        }
        return {
            locale,
            changeLanguage,
        }
    }
});
</script>

<style scoped lang="less">
.header_area{
    width: 100%;
    padding: 0;
    position: relative;
    .header_lang_area{
        width: 100%;
        height: 41px;
        min-width: 1280px;
        background-color: #2c3e50;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        .header_lang{
            width: 1280px;
            text-align: right;
            font-size: 12px;
            span {
                display: inline-block;
                padding: 0 10px;
                cursor: pointer;
            }
        }
    }
}
.main_area{
    padding: 0;
}
.footer_area{
    width: 100%;
    min-width: 1280px;
    background-color: #252525;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #cccccc;
    line-height: 24px;
    padding: 0;
}
</style>
  