<template>
  <el-config-provider :locale="elLocale">
    <router-view/>
  </el-config-provider>
</template>

<script>
import { defineComponent } from 'vue'
import { ElConfigProvider } from 'element-plus'
import { useI18n } from 'vue-i18n'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'
import ja from 'element-plus/dist/locale/ja.mjs'

export default defineComponent({
  components: { ElConfigProvider },
  setup() {
    const { locale } = useI18n()
    return {
      elLocale:locale.value === 'zh_CN' ? zhCn : locale.value === 'ja' ? ja : en ,
    }
  },
})
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
a{
  text-decoration: none;
  color: #333;
}
ul > li {
  list-style-type: none;     
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
