import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/views/layout.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/index',
    name: 'layout',
    component: Layout,
    children:[
      {
        path: '/index',
        name: 'home',
        component: () => import('../views/HomeView.vue')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/about/index.vue')
      },
      {
        path: '/develop',
        name: 'develop',
        component: () => import('../views/about/develop.vue')
      },
      {
        path: '/memorabilia',
        name: 'memorabilia',
        component: () => import('../views/about/memorabilia.vue')
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/about/contact.vue')
      },
      {
        path: '/careerDevelopment',
        name: 'careerDevelopment',
        component: () => import('../views/about/careerDevelopment.vue')
      },
      {
        path: '/joinUs',
        name: 'joinUs',
        component: () => import('../views/about/joinUs/joinUs.vue')
      },
      {
        path: '/joinDetail',
        name: 'joinDetail',
        component: () => import('../views/about/joinUs/joinDetail.vue')
      },
      {
        path: '/operation',
        name: 'operation',
        component: () => import('../views/about/operation.vue')
      },
      {
        path: '/proServe',
        name: 'proServe',
        component: () => import('../views/product/index.vue')
      },
      {
        path: '/powder',
        name: 'powder',
        component: () => import('../views/product/powder.vue')
      },
      {
        path: '/edge',
        name: 'edge',
        component: () => import('../views/product/edge.vue')
      },
      {
        path: '/flexView',
        name: 'flexView',
        component: () => import('../views/product/flex.vue')
      },
      {
        path: '/solution',
        name: 'solution',
        component: () => import('../views/product/solution.vue')
      },
      {
        path: '/solution/index',
        name: 'solutionIndex',
        component: () => import('../views/solution/index.vue')
      },
      {
        path: '/ecar',
        name: 'ecar',
        component: () => import('../views/solution/ecar.vue')
      },
      {
        path: '/other',
        name: 'other',
        component: () => import('../views/solution/other.vue')
      },
      {
        path: '/power',
        name: 'power',
        component: () => import('../views/solution/power.vue')
      },
      {
        path: '/pv',
        name: 'pv',
        component: () => import('../views/solution/pv.vue')
      },
      {
        path: '/traffic',
        name: 'traffic',
        component: () => import('../views/solution/traffic.vue')
      },
      {
        path: '/wind',
        name: 'wind',
        component: () => import('../views/solution/wind.vue')
      },
      {
        path: '/energyStorage',
        name: 'energyStorage',
        component: () => import('../views/solution/energyStorage.vue')
      },
      {
        path: '/technology',
        name: 'technology',
        component: () => import('../views/technology/index.vue')
      },
      {
        path: '/design',
        name: 'design',
        component: () => import('../views/technology/design.vue')
      },
      {
        path: '/test',
        name: 'test',
        component: () => import('../views/technology/test.vue')
      },
      {
        path: '/c1',
        name: 'c1',
        component: () => import('../views/calculation/c1.vue')
      },
      {
        path: '/c2',
        name: 'c2',
        component: () => import('../views/calculation/c2.vue')
      },
      {
        path: '/quality',
        name: 'quality',
        component: () => import('../views/quality/index.vue')
      },
      {
        path: '/technique',
        name: 'technique',
        component: () => import('../views/quality/technique.vue')
      },
      {
        path: '/relation/index',
        name: 'relationIndex',
        component: () => import('../views/inRelations/index.vue')
      },
      {
        path: '/relation/services',
        name: 'relationServices',
        component: () => import('../views/inRelations/services.vue')
      },
      { 
        path: '/:patchMatch(.*)*', 
        name:'home',
        component: () => import('../views/HomeView.vue') ,
        alias:'/index',
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router