<template>
  <div class="layout_footer">
    <div class="footer_nav_area" style="min-width: 1280px;">
      <div class="footer_nav" v-for="item in navListData" :key="item.id">
        <router-link class="footer_nav_title" :to="item.url" v-if="!item.isNotShow?.includes(locale)">{{ $t(`${item.name}`) }}</router-link>
        <div v-for="ic in item.children" :key="ic.id">
          <router-link :to="ic.url" v-if="!ic.isNotShow?.includes(locale)">
            <span v-if="ic.name === 'nav.EVIBB' && locale != 'ja'">{{ $t(`nav.EVIBB01`) }}{{ $t(`nav.EVIBB02`) }}</span>
            <span v-else>{{ $t(`${ic.name}`) }}</span>
          </router-link>
        </div>
      </div>
		</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'footerView',
  setup(){
    const { locale } = useI18n()

    interface navList {
      id:number,
      name:string,
      url:string,
      isNotShow?:string[]
      children?:navList[]
    }
    const navListData = ref<navList[]>([
      {
        id:2,
        name:'nav.about',
        url:'/about',
        children:[
          {
            id:201,
            name:'nav.aboutCompany',
            url:'/about',
            isNotShow:['ja']
          },
          {
            id:202,
            name:'nav.developmentHistory',
            url:'/develop',
            isNotShow:['en','ja']
          },
          {
            id:203,
            name:'nav.memorabilia',
            url:'/memorabilia',
            isNotShow:['en','ja']
          },
          {
            id:204,
            name:'nav.operation',
            url:'/operation',
            isNotShow:['ja']
          },
          {
            id:205,
            name:'nav.certificationSystem',
            url:'/quality'
          },
          {
            id:206,
            name:'nav.contact',
            url:'/contact'
          },
          {
            id:207,
            name:'nav.careerDevelopment',
            url:'/careerDevelopment',
            isNotShow:['en']
          },
          {
            id:208,
            name:'nav.joinUs',
            url:'/joinUs',
            isNotShow:['en']
          },
        ]
      },
      {
        id:3,
        name:'nav.proServices',
        url:'/proServe',
        children:[
          {
            id:301,
            name:'nav.powder',
            url:'/powder',
          },
          {
            id:302,
            name:'nav.edgeClosed',
            url:'/edge',
          },
          {
            id:303,
            name:'nav.EVIBB',
            url:'/flexView',
          },
          {
            id:304,
            name:'nav.complete',
            url:'/solution',
          },
        ]
      },
      {
        id:4,
        name:'nav.industrySolutions',
        url:'/solution/index',
        children:[
          {
            id:401,
            name:'nav.solarPower',
            url:'/pv',
          },
          {
            id:402,
            name:'nav.windPower',
            url:'/wind',
          },
          {
            id:403,
            name:'nav.transportation',
            url:'/traffic',
          },
          {
            id:404,
            name:'nav.powerGrid',
            url:'/power',
          },
          {
            id:405,
            name:'nav.electricalVehicle',
            url:'/ecar',
          },
          {
            id:406,
            name:'nav.energyStorage',
            url:'/energyStorage',
            isNotShow:['ja','en'],
          },
          {
            id:407,
            name:'nav.other',
            url:'/other',
          },
        ]
      },
      {
        id:5,
        name:'nav.engineering',
        url:'/technology',
        children:[
          {
            id:501,
            name:'nav.laminatedBusbarDesign',
            url:'/design',
          },
          {
            id:502,
            name:'nav.analysis',
            url:'/test',
          },
        ]
      },
      {
        id:6,
        name:'nav.design',
        url:'/c1',
        isNotShow:['ja','en'],
        children:[
          {
            id:601,
            name:'nav.laminated',
            url:'/c1',
            isNotShow:['ja','en'],
          },
          {
            id:602,
            name:'nav.parallel',
            url:'/c2',
            isNotShow:['ja','en'],
          },
        ]
      },
      {
        id:7,
        name:'nav.quality',
        url:'/quality',
        children:[
          {
            id:701,
            name:'nav.qualityAssurance',
            url:'/quality',
            isNotShow:['en','ja'],
          },
          {
            id:702,
            name:'nav.technology',
            url:'/technique',
          },
          {
            id:703,
            name:'nav.capability',
            url:'/technique',
          },
        ]
      }
    ])

    return {
      locale,
      navListData
    }
  }
});
</script>

<style scoped lang="less">
.layout_footer{
  width: 100%;
  background-color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  .footer_nav_area{
    width: 1280px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin: 35px 0 20px 0;
    .footer_nav{
      text-align: left;
      .footer_nav_title{
        font-size: 14px;
        margin-bottom: 10px;
      }
      a{
        font-size: 12px;
        line-height: 24px;
        color: #cccccc;
        margin-bottom: 8px;
        cursor: pointer;
      }
    }
  }
}
</style>
