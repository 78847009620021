export default {
    companyName:'版権の所属：蘇州西典機電有限公司',
    home:'トップページ',
    nav:{
        about:'WDIについて',
        aboutCompany:'公司简介',
        developmentHistory:'会社の大事記',
        memorabilia:'発展過程',
        operation:'运营方式与能力',
        certificationSystem:'品質保証',
        contact:'お問い合わせ',
        careerDevelopment:'採用情報',
        proServices:'製品・サービス',
        powder:'粉末塗装式ラミネートブスバー',
        edgeClosed:'エッジクローズ式ラミネートブスバー',
        EVIBB:'低インダクタンス・フレキシブル式ラミネートブスバー',
        complete:'お客様に最適な解決方案を提供',
        industrySolutions:'解決方案',
        solarPower:'PV発電',
        windPower:'風力発電',
        transportation:'輸送・運送事業',
        powerGrid:'電源システム',
        electricalVehicle:'新エネルギー自動車',
        energyStorage:'電気化学貯蔵エネルギー',
        other:'その他の工業分野',
        engineering:'技術・革新',
        laminatedBusbarDesign:'良好なラミネートブスバーの設計方法について',
        analysis:'分析と測定',
        design:'计算工具',
        laminated:'叠层母排设计辅助计算',
        parallel:'两平行母线的电感计算',
        quality:'品質保証',
        qualityAssurance:'西典的质量保证',
        technology:'製造技術',
        capability:'製造能力',
        investorRelations:'投資家関係',
        companyAnnouncement:'会社公告',
        investorCommunication:'投資家交流',
        investorServices:'投資家サービス'
    },
    index:{
        indexBannerBottom:'	 蘇州西典機電有限会社 (WDI)は中国蘇州にあるカナダ系の企業です。最先端の技術と製造に取り組んで、国内・海外のお客様に向けて、ラミネートブスバー 及び系統的な技術ソリューションをご提供している会社です。弊社の製品は新エネルギー（太陽光発電、風力発電）、輸送・交通システム、電源システム及び他の工業分野で幅広く応用されています。弊社はパワーエレ、デバイスと製造技術の分野における豊富な経験を備える精鋭技術チームがあり、お客様の要望に応じて、製品の開発、設計改良方案を提供しています。',
        index3ProDesc:'弊社の製品は先進・独自な技術で生産されています。製品及び製造技術において、数々の特許が得ました。',
        index3IndustryDesc:'長期以来、弊社は各業界のアプリケーション要求に応じて、最適な解決方案を提供しています。',
        index3Engineering:'弊社の技術チームは豊富な知識と経験により、持続にお客様に製品設計方案と改良方案を提供しています。',
        loadMore:'続き',
    },
    about:{
        aboutDesc01:'蘇州西典機電有限会社 (WDI)は中国蘇州にあるカナダ系の企業です。最先端の技術と製造に取り組んで、国内・海外のお客様に向けて、ラミネートブスバー及び系統的な技術ソリューションをご提供している会社です。弊社の製品は新エネルギー（太陽光発電、風力発電）、輸送・交通システム、電源システム及び他の工業分野で幅広く応用されています。',
        aboutDesc02:'弊社はパワーエレ、デバイスと製造技術の分野における豊富な経験を備える精鋭技術チームがあり、お客様の要望に応じて、製品の開発、設計改良の提供で支えています。弊社は高品質な製品と高付加価値の技術サービスを通して、お客様のグローバルチェーンの重要な一環になっています。お客様及びビジネスパートナーと共に、グロバール競争環境で成功・発展できるように努力していきます。',
        aboutDesc03:'弊社は先進・専門的な技術で製品を製造し、ラミネートブスバー及び製造技術において、数々の特許を得て、「国家高新技術企業」と「蘇州高新区先進企業」という名誉メダルを授与されました。',
    },
    operation:{
        title:'運営についての方式と能力',
        desc01:'- ラミネートブスバーの率先会社',
        desc02:'- お客様の開発案件を参与し、ラミネートブスバーの優秀な設計案を提供する',
        desc03:'- 特有な開発技術を備え、新製品の開発納期を短縮する',
        desc04:'- お客様至上の視点で、国内・海外にて販売・サービスチームを保有する',
        title01:'持続改善、完璧追求',
        desc05:'弊社はISO9001とIRIS（国際鉄道標準化）とTS16949の品質認証を取得しました。高品質な製品とサービスは弊社の存在及び持続発展の基礎となります。弊社は製品・サービスの持続改善、お客様の満足度向上を図ることに尽力していきます。',
        title02:'弊社の最終目標はお客様の100％の満足度を求めることです。',
    },
    careerDevelopment:{
        carDesc:'弊社は設立当初から持続改善、弛まず革新の理念を設計・生産に注いでいきました。会社の発展・成長は高水準、団結向上、勇敢革新のチームに繋がります。',
        carDesc01:'社員が安心で働ける給料・福祉と理想的な働く環境及び学習・発展のチャンスの提供することは弊社の長期で重要な目標です。',
        carDesc02:'各方面の人材が弊社に応募されることを歓迎致します。弊社の社員と共同で会社の発展に伴い、成長させます。',
        carDesc03:'ご応募頂きたい部門と職位があれば、下記の連絡先にご連絡してください。',
        carDesc03TEL:'TEL: 86-512-66165970',
        carDesc03Email:`Email: infor{'@'}wdint.com`
    },
    contact:{
        companyName:'蘇州西典機電有限公司',
        address:'所在地：中国  蘇州高新区金枫路353号',
        zipCode:'郵便番号: 215151',
        phone:'電話番号: 86-512-66165970',
        Fax:'Fax: 86-512-66572805',
        email:`E-mail: infor{'@'}wdint.com`,
        website:'Website: www.wdint.com',
        workTime:'勤務時間: 月曜日～金曜日、AM　8：30～PM　5：00　（中国時間）',
    },
    proServe:{
        title01:'製品・サービス',
        title0101:'ラミネートブスバーとは',
        title01Desc:'ラミネートブスバーは数層複合構造の接続基板です。低インダクタンスブスバーは伝統的な導体接続方法と比較して、回路の分布インダクタンスを大幅に減少させ、電力素子の過電圧（スイッチング時）を低減させ、電力素子の「超級基板」として、電力素子に大電流の流れる動作状態で最大の効能を発揮させています。',
        title02:'ラミネートブスバーの製品種類',
        title02Desc01:'- 更に低い総体のコスト',
        title02Desc02:'- 正確な組み付け作業',
        title02Desc03:'- 高い信頼性と安定性',
        title02Desc04:'- 適当なモジュール構造で、本体への取り付け作業と現場サービスが容易となる',
        title02Desc05:'- シンプルなデザインで、本体の内部スペースを縮小化',
        title02Desc06:'- 分布容量を増加',
        title02Desc07:'- 更に低い電圧で、高電流の流せる能力を実現',
        title02Desc08:'- ケーブルより常に放熱して、冷却し易く、温度上昇を小さくする',
        title02Desc09:'- より低いインピーダンス',
        title02Desc10:'- 非常に低いインダクタンス（特にIGBTに対する応用で、電圧が引き起こしたことによる電気部品の損害する確率を低下させる）',
        title03:'ラミネートブスバーの製品種類',
    },
    powder:{
        powderCon01:`性能の高いエポキシ樹脂を使用し、絶縁させるように単片のブスバーを塗装して、弊社の独自な積層技術で積層ラミネートすることを通して、全体の状態にさせた製品です。`,
        powderCon02:`弊社の独自な塗装技術は均一的に絶縁樹脂塗装表面厚みをコントールできます。各分野に応用されている製品の電気性能により、塗装表面の厚みを変えています。絶縁塗装表面の厚みが0.2ｍｍ～3ｍｍまでです。`,
        powderCon03:`当該粉末塗装ラミネートブスバーは滑らかで、光沢のある外観を持ち、良好な耐腐蝕性及び電気性能に優れています。`,
        powderCon04:`高電圧、大電流構造の高い仕様に適しています。`,
    },
    edgeClosed:{
        edgeClosedCon01:'数枚の導体同士の間及び表面に厚みの違うフィルムを敷いて、弊社の積層ラミネート技術で、全体の状態にさせた製品です。',
        edgeClosedCon02:'弊社にて設計・製造されているエッジクローズ式ラミネートブスバーはUL認証を受けた耐熱性、耐圧性に優れた絶縁フィルムを使って、独自の積層技術で生産を行っています。製品の最大なサイズは1900ｍｍ×600ｍｍまでです。',
    },
    flex:{
        flexTitle:'低インダクタンス・フレキシブル式ラミネートブスバー',
        flexDesc01:'当該製品（弊社の特許が得た製品）は常にパワースイッチングシステムに適用され、多相電力素子に接続できます。',
        flexDesc02:'この製品は最低のインダクタンスを持つと同時に、ある程度の柔軟性があります。伝統的なラミネートブスバーと比較し、大きな冷却面積がある為、大幅に温度上昇を低減させて、優れた電気性能を保有しています。',
    },
    complete:{
        completeDesc01:`弊社はパワーエレクトロニクス、原材料などの分野におけるプロフェッショナルな知識及び豊富な経験に基づいて、各アプリケーション（電気性能、空間構造、応用環境の湿度、動作温度、汚染レベルなど）の要求により、お客様に ラミネートブスバーの設計と高付加価値の技術サービスを提供しています。`,
        completeDesc02:`常用の数種類のラミネートブスバーの方案及び各方案の特徴・適応性を下記のように纏めました。`,
        completeTable00:`アイテム`,
        completeTable01:`PETフィルムでエッ ジクローズ式ラミネ ートブスバー`,
        completeTable02:`Nomexフィルムでエ ッジクローズ式ラミ ネートブスバー`,
        completeTable03:`エポキシ樹脂塗 装式ラミネート ブスバー`,
        completeTable10:`通常稼働電流(A)`,
        completeTable11:'10~1000',
        completeTable12:'10~1000',
        completeTable13:'10~2000',
        completeTable20:`通常稼働電圧(V)`,
        completeTable21:'380~4000',
        completeTable22:'380~4000',
        completeTable23:'380~4000',
        completeTable30:`長期連続稼働温度`,
        completeTable31:'130',
        completeTable32:'125',
        completeTable33:'130',
        completeTable40:`沿面距離`,
        completeTable41:`小さい（絶縁性のある障碍物の追 加或は絶縁シールの貼付けを通し て、沿面距離を増加させる）`,
        completeTable42:`小さい（絶縁性のある障碍物の追 加或は絶縁シールの貼付けを通し て、沿面距離を増加させる）`,
        completeTable43:'大きい',
        completeTable50:'耐湿度',
        completeTable51:'良い',
        completeTable52:'あまり良くない',
        completeTable53:'良い',
        completeTable60:`製品構造の複雑さ`,
        completeTable61:`平面的な形或は少量のベンダー作 業が必要`,
        completeTable62:`平面にフィルムに敷かれた箇所が ベンダー作業ができない `,
        completeTable63:`形状が複雑である`,
        completeTable70:`通常導体の厚み`,
        completeTable71:'推奨≤4 mm',
        completeTable72:'推奨≤4 mm',
        completeTable73:'推奨2~10',
    },
    industrySolutions:{
        title:'ラミネートブスバーのマーケット及びアプリケーション',
        inSolutionDesc01:'- 新エネルギー分野（例えば、太陽エネルギーインバーター、風力エネルギーコンバーター.）',
        inSolutionDesc02:'- 通信分野（例えば、通信機器のベースステーション、配電システム、サーバー、サーバー・メインエンジン）',
        inSolutionDesc03:'- 輸送・交通分野（軌道、電車）',
        inSolutionDesc04:'- その他のパワースイッチング分野',
    },
    pv:{
        pvDesc01:`今まで、弊社はインバーターの優秀なサプライヤーとして、お客様に最適の解決方案を提供しています。スペインの日光が十分に照射されている地帯から中国の北西地方の砂礫砂漠まで、弊社の製品が安全・安定に動作しています。`,
        pvDesc02:'PV業界は持続的に発電コストの低減、システム効率を上げ、長期動作時期中の安定性にチャレンジしています。業界の特徴である需要の大幅な変動、増減に迅速に対応し、お客様のご要求に確実に対応する供給能力を備えています。',
        pvDesc03:'長期以来、弊社はお客様と共同で提携して、方案設計の最初段階からコストを低減させています。弊社が先進な製造技術と生産能力に基づいて、迅速的な対応能力でお客様に強力なサポートを提供しています。',
        pvProDesc01:'粉末塗装式ラミネートブスバーは長期持続動作温度が130度になります。あらゆる銅端子の周りに絶縁樹脂に覆われて、最少の沿面距離が30ｍｍになり、PV業界に大幅に応用されています。',
        pvProDesc02:'この製品は弊社の特許技術により、生産された製品です。放熱のバランスを取ると同時に、製品の軽量化とコストダウンを実現しています。',
    },
    wind:{
        windDesc01:'風力発電の業界は高コスト、劣悪な環境（風雨雷電、砂塵）などの状況に挑戦しています。特に海上風力発電は高腐蝕性と高汚染クラスの特殊な海洋環境です。それだから、ラミネートブスバーは長期で正常の動作状態と高い安定性を必要とされるのです。',
        windDesc02:'弊社は風力発電業界における数年の設計経験により、海上風力発電と陸地高海抜風力発電及び低風速（5.5ｍ/ｓ以下）の風の吹く地帯でのアプリケーション環境の特徴に対して、多くのラミネートブスバーの最適の解決方案を提供しています。',
        windProDesc01:'ハイ・パワーの海上風力発電コンバーターに使用されるラミネートブスバーに対して、その複雑な三レベル位相構造と高い動作電圧、及び製品の耐腐蝕性の方で速やかに関係問題の解決をしなければなりません。',
        windProDesc02:'コンデンサーに接続するブスバーはエッジクローズ式ラミネートブスバーのような設計を実施しています。その接続部は「プラス-ゼロ-プラス」、「マイナス-ゼロ-マイナス」の構造で設計しています。電気ギャップと沿面距離を保障すると同時に、大幅にインダクタンスを低減させています。',
        windProDesc0202:'IGBTに接続するブスバーは絶縁粉末塗装式ラミネートブスバーのような設計を実施しています。電気性能の方で特に極と極の間の沿面距離を保障させています。且つ、各端子の間の距離を近くさせて、大いに寄生インダクタンス（Parasitic inductance）を低減させています。',
    },
    traffic:{
        trafficDesc01:'輸送・運送設備の動作環境は非常に劣悪で、振動・衝撃、環境の温度・湿度、標高、耐腐蝕性などの要素が ラミネートブスバーの耐腐蝕性と安定性への要求が更に高いです。特に、鉄道事業において、旅客輸送の高速、貨物輸送の重負荷、電化の時代になり、列車への安全性と安定性の要求も高くなります。',
        trafficDesc02:'輸送・交通機器はその荷重部の体積、重量への要求が高くなります。駆動牽引コンバーターは受けるピーク電流と過電圧値が高いです。',
        trafficDesc03:'弊社は高電圧、大電流において、豊富な設計経験があり、設計と製造をIRIS（国際鉄道標準）基準により、行っています。',
        trafficProDesc01:'左図のように、この製品は新型機器の牽引コンバーター用のラミネートブスバーです。定格動作電圧が2800Vで、定格電流が1080Aです。電気性能の要求を満たす前提で、モジュール重量の問題も解決しました。',
        trafficProDesc02:`牽引コンバーター用のラミネートブスバーはエッジクローズ式ラミネートブスバーと粉末塗装式ラミネートブスバー共に使用しています。電気性能の要求に満足した上で、スペースを縮小させ、空間不足の問題を無くしました。`,
        trafficProDesc03:'牽引機器の補助コンバーターに適用し、塗装厚さが2ｍｍで、耐圧が9600Vです。',
    },
    power:{
        powerTitle:`電源システム`,
        powerDesc01:'新エネルギーで発電する比率がどんどん高くなり、制御する端末も増え、それらの電源システムにはより高い信頼性がが求められています。電力配電とエンドユーザー経由で、電気エネルギー品質を改善しています。その為、ＨＶＤＣ、ＳＶＧ、アクティブフィルター、コンバーターなどの製品技術が幅広く応用されています。',
        powerDesc02:'上記の製品に使われるラミネートブスバーは他の分野で応用されたラミネートブスバーとは違い、その特徴は大電圧、大電流、コロナ放電に関する高要求、及び製品の高安定性です。上記の要求に満足できるメーカーは数少ないですが、弊社はそのひとつの専門メーカーです。特に製品設計の方で、業界のトップ地位になっています。',
        powerProDesc01:'エポキシ樹脂は絶縁性能、耐熱性能と機械性能に優れて、広範に安定性要求の高い送電・配電分野に適用されています。送電・配電の分野で、弊社の製品は従来の裸の銅導体を代替し、電気ギャップが空間距離の要求程度を減少させ、有限の空間でスペースを縮小しました。',
        powerProDesc02:`この製品はSVGに使われています。狭い組み付け空間で、弊社のエッジクローズ式ラミネートブスバーを使って、大いにスペースを節約し、沿面距離（IGBTとの接触面）を増加しました。`,
    },
    ecar:{
        ecarDesc01:'新エネルギー自動車は21世紀に向け、成長する新しい分野です。この業界の技術が益々成熟しています。それに伴い、電力システムも持続で改良・更新するしかありません。と同時に、メーカーに設計の能力に対しての要求が更に厳しくなります。また、新エネルギー自動車のコストが高く、設計の段階でコストを引き下げることがお客様と弊社の共同目標になります。',
        ecarDesc02:'弊社は昔からこの分野に入りましたが、国内・海外のお客様と提携頂き、開発していました。新エネルギー自動車に適用されているラミネートブスバーの方で、弊社は豊かな技術経験と数々の特許を得ました。',
        ecarProDesc0101:'EV-CCS 電池解決案（Integrated Bus Bar for Battery)',
        ecarProDesc0102:'CCSというのはPCB/FPC（信号回路）とLBB（電力伝送）が集合された一体化の集成案です。',
        ecarProDesc0103:'- 温度センサーの採取及び電圧信号の採取の集成',
        ecarProDesc0104:'- コネクターの集成',
        ecarProDesc0105:'- ヒューズ保護などのSMT部品の集成',
        ecarProDesc0106:'- 間違い接続作業の途絶',
        ecarProDesc0107:'- 半田付作業と組み付け作業の減少',
        ecarProDesc0108:'- ユニットスペースが更に整え、部品体積が更に小さい',
        ecarProDesc0109:'- 性能更に優越になり、安定性が更に良い',
        ecarProDesc0110:'- 高度の集成化、電動自動車の自動化の量産に最適',
        ecarProDesc0201:`電気駆動システムに対しての解決案`,
        ecarProDesc0202:`- 最小のインダクタンス`,
        ecarProDesc0203:`- 複雑な電力制御と接続仕方に適用`,
        ecarProDesc0204:`- ユニットスペースが更に整え、部品体積が更に小さい`,
        ecarProDesc0205:`- 多種の絶縁方法の解決案（エポキシ樹脂粉末、PET、PIフィルム等の案）`,
        ecarProDesc0206:`- 更に高い温度に適応`,
        ecarProDesc0207:`- 更に厳酷な環境に適応（油、塩水、霧等）`,
    },
    es:{
        esDesc01:'电化学储能为代表的新型储能, 目前正处于由商业化初期步入规模化发展的关键阶段。目前储能电池领域仍较多使用老一代线束产品；随着储能电池领域对电芯采样要求的提升，集成度更高的CCS方案将快速替代传统线束方案。西典将充分利用动力电池领域的成功经验，在储能电池领域实现快速增长。',
        esDesc02:'除CCS外，边缘封闭式压合母排LBB（Laminated Bus Bar）作为电化学储能系统中储能变流器（Power Conversion System）的核心子系统之一，配合储能变流器实现控制蓄电池的充电和放电，进行交直流变换，以及在无电网情况下直接为交流负荷供电等功能。西典具有近20年边缘封闭式压合母排LBB开发生产经验，所生产的边缘封闭式压合母排具有更少的总体成本，很高的可靠性和安全性，节省内部空间，降低杂散电感，降低尖峰电压保护IGBT，更低的阻抗，无错误的安装，增加分布电容，以更低的电压降实现高电流承载能力，通常比电缆更容易散热冷却，更小的温升等优点。',
        esPro01Title:'LBB for PCS solution',
        esProDesc01Desc01:'Compact, easy installation',
        esProDesc01Desc02:'Low inductance, superior performance',
        esProDesc01Desc03:'Higher current carrying capacity',
        esPro02Title:'CCS for energy storage solution',
        esProDesc02Desc01:'Strong anti-aging capabilities, longer product life',
        esProDesc02Desc02:'FFC solution has good signal acquisition as an alternative of FPC.',
        esProDesc02Desc03:'Higher current carrying capacity',
        esProDesc02Desc04:'Max size CCS: 2000 mm x 500 mm',
    },
    other:{
        otherDesc01:'産業用コンバーターは幅広く電力、冶金、石油工業、化学工業、エアコンなどの業界に応用されています。効率、エネルギーを向上させることが工業分野で絶えず追求する目標になりました。ラミネートブスバーは産業コンバーター業界へ応用することが技術動向になってきております。伝統的な裸の銅導体と比較し、ラミネートブスバーは低インダクタンス、良好な絶縁性能と耐気候の特徴を備えています。と同時に、組立スペースの縮小化にさせ、有効的に製品の長期で動作の信頼性を保障させ、製品のアフターサービス費用を節約させます。',
        otherDesc02:`工業分野における各種のアプリケーション要求により、弊社は各要求項目に対して、ラミネートブスバーの解決方案を提供しています。`,
    },
    technology:{
        technologyTitle:'精鋭技術チーム',
        technologyDesc01:'弊社はカナダに登記のエンジニアを含め、博士と修士などの学位を得たベテランの設計チームを備えています。パワーエレ機器、材料などの業界における豊富な知識と設計経験に優れています。',
        technologyDesc02:'弊社のエンジニアは技術要求に応じて、最高の設計と立派な解決方案を提供できます。速やかに新製品を市場に導入できます。',
        technologyDesc03:'弊社は卓越した独自設計により、お客様に製品の改良設計方案を提供しています。設計の段階から最低のコストと優れる製品性能に達成できるように尽力しています。このようにして、お客様の製品の競争力の維持を協力させます。',
        technologyTitle01:'製品の設計と開発',
        technologyDesc04:'- 新製品の構想から量産までの対応',
        technologyDesc05:'- 製品の新たな設計を通して、性能の向上或はコストの引下げを実施',
        technologyDesc06:'- 製造方法と技術工程の研究・進化',
        technologyDesc07:'- 試作サンプルを迅速に対応する為に、Quick Prototypingの独自な技術で、新製品の開発納期を短縮化',
    },
    design:{
        designTitle:`良好なラミネートブスバーの設計方法について`,
        designTitle01:'設計の主な構想',
        designDesc01:'ラミネートブスバーはIGBTとコンデンサーの間の低インダクタンスの接続に役に立ています。 その為、製品を設計する際に、IGBT、IGBT駆動回路、コンデンサーなどのレイアウト、及びラジエーターなど全体の状況を考慮する必要があり、電気性能、放熱、レイアウトなどの要求を満足させなくてはいけません。',
        designTitle02:'インダクタンスがシステムへの影響：良好な設計方案はラミネートブスバーのインダクタンスを最小限にさせること',
        designTitle03:'システムの温度上昇を低減',
        designDesc02:'インバーター或はコンバーターシステムの温度上昇が高過ぎると、システムの信頼性と寿命に影響を与え、インバーターを焼失させることに至ります。ラミネートブスバーはシステムの中の非常に重要な部品として、設計時に、温度上昇の要素を慎重に考慮する必要があります。',
        designDesc03:'ラミネートブスバーの温度上昇の設計は発熱と放熱の両方で考慮が必要であり、電流の均等性を向上し、導体の厚みを変え、放熱面積を増加することです。　最高の設計方案を通して、製品性能に満足できる最適な製品を提供します。',
        designTitle04:'電気性能の要求を満たす（耐圧、コロナ放電、沿面距離など）',
        designDesc04:'ラミネートブスバーを設計する際に、導体と絶縁材料の選択、沿面距離を増加するなどの方法経由で、及び先進な製造技術を総合し、　その電気性能に満足させます。',
        designTitle05:'生産性・コスト',
        designDesc05:'ラミネートブスバーを設計する時には、具体的な製造技術を考える必要があります。多くのお客様の場合はモジュールのファンクションと効果に注目し、ラミネートブスバー自身の生産性を無視しています。その場合、工程の複雑さと生産コストは高くなってしまいます。弊社は設計において、全てのお客様に多くの付加価値を提供できる設計をしています。',
        designTitle06:'組み付け性能',
        designDesc06:'弊社はラミネートブスバーにアプリケーション環境へ適用する空間構造の要求、及び機械性能の要求に満足させます。'
    },
    test:{
        testTitle01:'温度上昇についての分析と測定', 
        testDesc01:'ラミネートブスバーの設計初期は弊社がシミュレーションの分析と温度上昇の測定に基づいて、技術パラメーターを検証します。この試験は弊社の実験室で行い、管理された温度上昇測定設備で測定しています。',
        testDesc02Title:`特別説明：`,
        testDesc02:'たとえ温度上昇測定結果はラミネートブスバーの温度上昇が設計値の範囲内になることを証明できても、お客様にラミネートブスバーに最終使用製品に組み付けて、システムに十分な負荷で稼働させた状態で、ホットイメージング装置で 新たに温度上昇をスキャンすることを提案します。最終使用製品に組み付けられたラミネートブスバーの温度上昇は 他の部品との組み付け距離、周りのエアフロー、IGBTの温度上昇などの影響も考えられます。',
        testTitle02:'インダクタンスの測定',
        testDesc03:'サンプルを試作した後に、インダクタンスの測定を通して、設計のパラメーターを検証します。ラミネートブスバーのインダクタンスはｎHから数十ｎHまでですが、微量のインダクタンスに対しての測定は弊社の精密設備で行います。',
        testDesc04:'弊社はその他の先進的な開発、電気性能、機械性能に関する測定設備も保有し、技術チームの設計・開発を協力させ、 お客様に最適な設計方案と性能の良好な製品を提供します。',
    },
    certificationSystem:{
        breadTitle:'品質保証',
        title:'品質保証',
        cerDesc:'弊社は耐圧測定、コロナ放電測定、接触電気抵抗測定、温度上昇測定、インダクタンス測定、レーザー寸法検査など の専門的な検査技術を備え、最先端な設備を保有しています。お客様に高品質の製品の提供することが保証できます。',
        cerDesc01:'先進的な現場電子化の文書管理システムは少量多種の生産タイプの図面管理を正確にし、安全な機密保持管理をしています。',
        cerDesc02:'個々の製品毎にバーコードが付かされ、原材料納入から各加工工程、組立・ラミネート工程、最後の測定工程まで 全生産過程の品質トレーサビリティ作業ができます。',
        cerDesc03:'弊社の製品は多くのお客様において、数々の「優秀なサプライヤー」として評価され、また、「技術革新賞」も受賞しています。',
        title01:'ISO 9001とIRIS（国際鉄道標準）及びTS16949認証の取得',
        cerDesc04:'弊社はIRIS（国際鉄道標準）とTS16949の認証を取得しました。弊社の製品が全工程の品質管理、製品生命周期性管理、アイテム管理の方で、 国際先進なレベルに達成することは証明できます。',
    },
    technique:{
        techniqueTitle:'率先の製造技術と製造能力',
        techniqueDesc01:'持続革新、卓越追求の理念は弊社の製品全般（製品設計、製造技術、製品測定、製品の長寿命、高信頼性） に貫いています。',
        techniqueDesc02:'弊社は専用のラミネートをすること、Z字状とL字状のベンダー作業、高圧絶縁エポキシ樹脂の塗装、短納期のサンプル試作などの加工技術の方で 業界の上位になっています。',
        techniqueTitle01:'サンプル開発技術',
        techniqueDesc03:'弊社は革新性のあるQuick Prototyping柔性迅速試作技術（弊社の特許技術*）で、お客様のサンプルの開発納期を短縮させ、新製品を迅速に市場へ導入させると同時に、製品の初期の投入とコストを低減させます。',
        techniqueTitle02:'ラミネート・ベンダー技術',
        techniqueDesc04:'先進的で専用の自動ラミネート設備は技術パラメーターを高精度でコントロールでき、高品質の品質と性能が保証できます。',
        techniqueDesc05:'独自のZ字状とL字状のベンダー技術（弊社の特許技術*）は伝統的な数回高温ラミネートすることを減少させ、絶縁層の損害を減らし、製品のコロナ放電の性能を向上させます。',
        techniqueTitle03:'ブスバー製造能力',
        techniqueDesc06:'当社の設備/生産ラインはCNC加工機（CNC lathing, CNC milling, CNCpunching/forming）、挟み工具と金型の製造、板金加工機、ベンダー、ろう付け、エポキシ樹脂塗装、ラミネート作業、組立、測定などを含めています。',
        techniqueDesc07:'電気メッキ以外の作業は弊社で行います。ラミネートブスバーの内部管理と製品品質の全体をコントールすることが保証できます。',
    } 
}