// 实现语言切换
import { createI18n } from 'vue-i18n'
import zh_CN from './language/zhCn'
import en from './language/en'
import ja from './language/ja'
const lang = 'en'

const i18n = createI18n({
    legacy:false,
    globalInjection:true,
    locale:lang,
    messages:{
        zh_CN,
        en,
        ja
    }
})

export default i18n